<template>
  <div class='page-box'>
    <base-screen class="custom-screen">
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-row>
          <a-form-model-item prop="loginName" label="申请账号">
            <a-input allow-clear v-model="queryRef.loginName" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item prop="userNo" label="用户编号">
            <a-input allow-clear v-model="queryRef.userNo" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item prop="orderNo" label="订单编号">
            <a-input allow-clear v-model="queryRef.orderNo" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item label="审核状态" prop="state">
            <a-select v-model="queryRef.state" placeholder='请选择' style="width: 200px">
              <a-select-option v-for='item in asSaleState' :key='item.value' :value="item.value">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item class="screen-btns">
            <a-button type="primary" @click="handleSearch">查询</a-button>
            <a-button @click="handleReset">重置</a-button>
          </a-form-model-item>
        </a-row>
      </a-form-model>
    </base-screen>
<!--    <div class="btn-box">-->
<!--      <a-button type="primary" @click="exportFn" :loading="exporting"> 导出 </a-button>-->
<!--    </div>-->
    <base-table :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :rowKey="record => record.logId" :loading="loadingRef" @change="handleTableChange">
      <template #state="record">
        <span>{{asSaleState.find(x=>x.value === record).name}}</span>
      </template>
      <template #operation="_,record">
        <div class='table-operations '>
          <a-button type='link' @click='pass(record)' v-if='record.state == 0'>通过</a-button>
          <a-button type='link' @click='refused(record)' v-if='record.state == 0'>拒绝</a-button>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import { shop } from '@/api'
import { asSaleState } from '../../utils/constants'
const columns = [
  {
    title: '申请账号',
    dataIndex: 'loginName',
  },
  {
    title: '用户编号',
    dataIndex: 'userNo',
  },
  {
    title: '订单编号',
    dataIndex: 'orderNo',
  },
  {
    title: '售卖数量',
    dataIndex: 'totalAmount',
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: '提交时间',
    dataIndex: 'createTime',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
]
export default {
  name: 'PageAsSale',
  components: {
  },
  setup (props, { root }) {
    const formRef = ref(null)
    const formAddRef = ref(null)
    const rules = ref({
      name: [
        { required: true, message: '请输入', trigger: 'change' },
      ],
      content: [
        { required: true, message: '请输入', trigger: 'change' },
      ],
    })
    const key = ref(0)
    const logId = ref(null)
    const state = ref(null)
    const queryRef = ref({
      state: undefined,
    })
    const dataRef = ref([])
    const pageRtv = ref({
      current: 1,
      size: 10,
      total: 0,
    })
    const form = ref({
      name: undefined,
      allotWaitMoney: undefined,
      priceShow: undefined,
      saleAsTotal: 0,
      content: undefined,
      state: 0,
    })
    const loadingRef = ref(false)
    onMounted(() => {
      Promise.all([getTableData()])
    })
    async function getTableData () {
      loadingRef.value = true
      dataRef.value = []
      const { data, page } = await shop.getAsSalePage({ ...queryRef.value, ...pageRtv.value })
      loadingRef.value = false

      dataRef.value = data || []
      pageRtv.value.total = page.total
    }

    function handleSearch () {
      pageRtv.value.current = 1
      getTableData()
    }

    async function handleReset () {
      formRef.value.resetFields()
      await root.$nextTick()
      handleSearch()
    }

    function handleTableChange ({ current }) {
      pageRtv.value.current = current
      getTableData()
    }
    async function getEpBuyDetail (record) {
      const { code, data } = await shop.getEpBuyDetail(record.typeId)
      if (code === '00000') {
        form.value = {
          ...data,
          img: record.img
        }
        visibleEp.value = true
      } else {
        root.$message.warn('详情获取失败，请稍后重试')
      }
    }
    async function handelCancel () {
      formAddRef.value.resetFields()
    }
    async function deleteLog (record) {
      root.$confirm({
        title: '操作提示',
        content: '确认刪除吗？',
        onOk: async () => {
          const { code, msg } = await shop.deleteSale({
            logId: record.logId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }
    async function pass (record) {
      root.$confirm({
        title: '操作提示',
        content: '确定通过该售卖申请吗？',
        onOk: async () => {
          const { code, msg } = await shop.passAsSale({
            userAccountAsSaleId: record.userAccountAsSaleId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }
    async function refused (record) {
      root.$confirm({
        title: '操作提示',
        content: '确定拒绝该售卖申请吗？',
        onOk: async () => {
          const { code, msg } = await shop.refusedAsSale({
            userAccountAsSaleId: record.userAccountAsSaleId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }
    const previewImage = ref('')
    const visibleEp = ref(false)
    const baseUrl = process.env.VUE_APP_IMG
    return {
      queryRef,
      formRef,
      formAddRef,
      handleTableChange,
      handleReset,
      handleSearch,
      key,
      logId,
      state,
      handelCancel,
      columns,
      rules,
      form,
      asSaleState,
      loadingRef,
      dataRef,
      pageRtv,
      getTableData,
      deleteLog,
      pass,
      refused,
      getEpBuyDetail,
      baseUrl,
      previewImage,
      visibleEp,

    }
  },
}
</script>

<style lang="less" scoped>
@label-width: 84px;
.custom-screen {
  ::v-deep .solt-div {
    padding: 10px 0 0;
  }
  ::v-deep .ant-form-item-label {
    width: @label-width;
  }
}
.screen-btns {
  margin-left: @label-width;
}
.btn-date-shortcut {
  margin: 4px 12px 0 0;
  &:last-child {
    margin-right: 0;
  }
}
.record-list {
  margin-bottom: 32px;
  .record-item {
    margin-bottom: 8px;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
